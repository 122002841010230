import { useEffect, useState } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import FlashOnOutlinedIcon from '@mui/icons-material/FlashOnOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import TollIcon from '@mui/icons-material/Toll';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

// Local imports
import color from '../../styles/color';
import { formatNumberWithCommas, getCurrencySymbol } from '../../utils/format';

interface SubPaymentDetailsCotainerProps {
	status: number; // 0=process, 1=approved, 2=reject
    amount: number;
    isAllowUIComplete: boolean;
    hasRebate: boolean;
    rebateAmount?: number;
    currencyCode: string;
    callBack: () => void;
}

export const SubPaymentDetailsCotainer: React.FC<SubPaymentDetailsCotainerProps> = ({ status, amount, isAllowUIComplete, hasRebate, rebateAmount, currencyCode = 'CNY', callBack }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [bntText, setBtnText] = useState<string>('');
	const [btnColor, setBtnColor] = useState<string>('');
	const [btnTextColor, setBtnTextColor] = useState<string>('');
	const [containerBorderLeftColor, setContainerBorderLeftColor] = useState<string>('');
    
    function processDetails() {
        if(status === 0 && isAllowUIComplete) {
            setBtnText('ConfirmTransaction');
            setBtnColor(color.sky.sky500);
            setBtnTextColor(color.white);
            setContainerBorderLeftColor(color.sky.sky500);
        } else if(status === 0 && !isAllowUIComplete) {
            setBtnText('Processing');
            setBtnColor(color.sky.sky100);
            setBtnTextColor(color.sky.sky500);
            setContainerBorderLeftColor(color.sky.sky500);
        } else if(status === 1) {
            setBtnText('WithdrawCompleted');
            setBtnColor(color.emerald.emerald50);
            setBtnTextColor(color.emerald.emerald500);
            setContainerBorderLeftColor(color.emerald.emerald500);
        } else if(status === 2) {
            setBtnText('WithdrawFailed');
            setBtnColor(color.rose.rose50);
            setBtnTextColor(color.rose.rose600);
            setContainerBorderLeftColor(color.rose.rose500);
        }
    }

    useEffect(() => {
        processDetails();
	}, [status]);

    const styles = {
        container: {
            paddingLeft: 2, 
            paddingRight: 2,
        },
        flashOnIcon: {
            fontSize: '16px', 
            marginLeft: 1, 
            marginRight: 1, 
            color: color.amber.amber500, 
            verticalAlign: 'sub',
        },
        button: {
            borderRadius: '12px', 
            width: '35vh', 
            paddingTop: 10, 
            paddingBottom: 10, 
            backgroundColor: btnColor,
            color: btnTextColor,
        },
        btnIcon: {
            fontSize: '18px', 
            marginLeft: 1, 
            marginRight: 1, 
            verticalAlign: 'sub',
        },
        mbInnerContainer: {
            marginTop: '7px', 
            marginBottom: '7px', 
            paddingLeft: 2, 
            paddingRight: 2,
        },
        mbFlashOnIcon: {
            fontSize: '14px', 
            marginLeft: 1, 
            marginRight: 1,
            color: color.amber.amber500, 
            verticalAlign: 'sub',
        },
        mbButton: {
            borderRadius: '12px', 
            width: '100%', 
            backgroundColor: btnColor, 
            color: btnTextColor, 
            paddingTop: 10, 
            paddingBottom: 10,
        },
        mbBtnIcon: {
            fontSize: '16px', 
            marginLeft: 1, 
            marginRight: 1, 
            verticalAlign: 'sub',
        },
        description: {
            background: `linear-gradient(transparent 30%, ${color.amber.amber100} 60%, ${color.amber.amber100} 85%, transparent 85%)`,
            fontWeight: 'bold',
            padding: '0 2px',
        }
    }

    return (
        <>
            {!isMobile ? (
                // PC view
                <Grid container border={`1px solid ${color.slate.slate300}`} borderRadius={'12px'}>
                    <Grid container borderLeft={`2px solid ${containerBorderLeftColor}`} mt={'7px'} mb={'7px'}>
                        <Grid item xs={4} md={4} alignSelf={'center'}>
                            <Grid container justifyContent={'space-between'} sx={styles.container}>
                                <Grid container alignItems={'center'}>
                                    <Typography variant='body2' mr={2}>
                                        <span>{t('SubWithAmount')}</span>
                                    </Typography>
                                    <Typography variant='h6' fontWeight={'bold'}>
                                        <span>{getCurrencySymbol(currencyCode) + formatNumberWithCommas(amount)}</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={8} md={8}>
                            <Grid container pr={'5px'} justifyContent={'flex-end'} alignItems={'center'}>
                                { isAllowUIComplete && 
                                    <Typography variant='body2' mr={2} width={'50%'}>
                                        <FlashOnOutlinedIcon sx={styles.flashOnIcon}/>
                                        <span style={styles.description}>
                                            {
                                                hasRebate ?
                                                t('transactionComplete', {currency: getCurrencySymbol(currencyCode), rebateAmount: formatNumberWithCommas(rebateAmount || 0) })
                                                // `交易完成请点击『 确认到账 』，可获得额外红利 ￥${formatNumberWithCommas(rebateAmount || 0)}`
                                                : 
                                                t('PleaseClickConfirm')
                                            }
                                        </span>
                                    </Typography>
                                }
                                    <Button onClick={callBack} disabled={!(status === 0 && isAllowUIComplete)} variant="contained" style={styles.button}>
                                        <Typography variant='body2' sx={{ textTransform: 'none' }}>
                                            {
                                                status === 0 ?
                                                    <HistoryOutlinedIcon sx={styles.btnIcon} />
                                                : status === 1 ?
                                                <TollIcon sx={styles.btnIcon} />
                                                : status === 2 ?
                                                <WarningAmberIcon sx={styles.btnIcon} />
                                                : null
                                            }
                                            <span>{t(bntText)}</span>
                                        </Typography>
                                    </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
            :
            (
                // Mobile view
                <Grid container border={`1px solid ${color.slate.slate300}`} borderRadius={'12px'}>
                    <Grid container justifyContent={'center'} sx={styles.mbInnerContainer} borderLeft={`2px solid ${containerBorderLeftColor}`}>
                        <Grid item mb={2} xs={12}>
                            <Grid container flexDirection={'row'} justifyContent={'center'} alignItems={'baseline'}>
                                <Typography variant='body2' mr={2} color={color.slate.slate500}>
                                    <span>{t('SubWithAmount')}</span>
                                </Typography>
                                <Typography variant='body1' fontWeight={'bold'}>
                                    <span>{getCurrencySymbol(currencyCode) + formatNumberWithCommas(amount)}</span>
                                </Typography>
                            </Grid>
                        </Grid>
                        { isAllowUIComplete && 
                            <Grid item mb={2} textAlign={'center'}>
                                <Typography variant='body2'>
                                    <FlashOnOutlinedIcon sx={styles.mbFlashOnIcon}/>
                                    <span style={styles.description}>
                                        {
                                            hasRebate ?
                                            t('transactionComplete', {currency: getCurrencySymbol(currencyCode), rebateAmount: formatNumberWithCommas(rebateAmount || 0) })
                                            : 
                                            t('PleaseClickConfirm')
                                        }
                                    </span>
                                </Typography>
                            </Grid>
                        }
                        <Grid item xs={12} mb={1} textAlign={'center'}>
                            <Button onClick={callBack} variant="contained" disabled={!(status === 0 && isAllowUIComplete)} style={styles.mbButton}>
                                <Typography variant='body2' sx={{ textTransform: 'none' }}>
                                    {
                                        status === 0 ?
                                            <HistoryOutlinedIcon sx={styles.mbBtnIcon} />
                                        : status === 1 ?
                                        <TollIcon sx={styles.mbBtnIcon} />
                                        : status === 2 ?
                                        <WarningAmberIcon sx={styles.mbBtnIcon} />
                                        : null
                                    }
                                    <span>{t(bntText)}</span>
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default SubPaymentDetailsCotainer;