// Function to change the language based on currency code
export const changeLanguageByCurrency = (i18n: any, currencyCode: string) => {
    const languageMap: { [key: string]: string } = {
        'CNY': 'cn',
        'THB': 'th',
        'VND': 'vi',
        'default': 'en',
    };

    const language = languageMap[currencyCode] || languageMap['default'];
    i18n.changeLanguage(language);  // Now we are directly using the global i18n instance
};