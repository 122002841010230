import { useEffect, useState } from 'react';
import { Grid, Box, Typography, Snackbar, Alert } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import TollIcon from '@mui/icons-material/Toll';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Local imports
import color from '../../styles/color';
import WarningMessageContainer from '../../components/warningMessageContainer';
import SubPaymentDetailsCotainer from '../../components/subPaymentDetailsContainer';
import ConfirmTransactionModal from '../../components/confirmTransactionModal';
import AlertModal from '../../components/alertModal';
import * as WithdrawalApi from '../../apiIntegration/withdrawalApi';
import { GetSplitwithdrawalResult } from '../../apiIntegration/models/withdrawal/getWithdrawal';
import { formatNumberWithCommas, formatDate, getCurrencySymbol } from '../../utils/format';
import SimpleBackDrop from '../../components/backDrop/index';
import { changeLanguageByCurrency } from '../../utils/commonUtils';

function Withdrawal() {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [backDropOpen, setBackDropOpen] = useState<boolean>(false);
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
	const [splitWithdrawalData, setSplitWithdrawalData] = useState<GetSplitwithdrawalResult>();
	const [hasRebate, setHasRebate] = useState<boolean>(false);
	const [isAlertModalOpen, setIsAlertModalOpen] = useState<boolean>(false);
	const [token, setToken] = useState<string>('');
	const [selectedSplitWithdrawalOrderId, setSelectedSplitWithdrawalOrderId] = useState<string>('');
    const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [isConfirmTransSuccess, setIsConfirmTransSuccess] = useState<boolean>(false);
	const [isAnyAllowUIComplete, setIsAnyAllowUIComplete] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Get the current URL
        const url = window.location.href;
        // Create a new URL object
        const urlObj = new URL(url);
        // Get the search parameters
        const params = new URLSearchParams(urlObj.search);
        // Access the token parameter
        setToken(params.get('token')!);
        apiList.getSplitwithdrawal(params.get('token')!);
	}, []);

    const styles = {
        headerContainer: {
            padding: 2, 
            background: 'transparent linear-gradient(96deg, #075985 0%, #0C4A6E 100%) 0% 0% no-repeat padding-box;'
        },
        headerDivider: {
            marginTop: '2vh', 
            marginBottom: '2vh'
        },
        transInfoIcon: {
            fontSize: '16px', 
            marginLeft: 1, 
            marginRight: 1, 
            verticalAlign: 'sub',
            cursor: 'pointer'
        },
        transInfoProccessContainer: {
            backgroundColor: splitWithdrawalData?.withdrawalData.status === 2 ? color.blue.blue50 : splitWithdrawalData?.withdrawalData.status === 3 ? color.rose.rose50 : color.green.green50,
            border: `1px solid ${splitWithdrawalData?.withdrawalData.status === 2 ? color.blue.blue600 : splitWithdrawalData?.withdrawalData.status === 3 ? color.rose.rose600 : color.green.green600}`, 
            padding: 5, 
            paddingLeft: 10, 
            paddingRight: 10, 
            borderRadius: '40px', 
            color: splitWithdrawalData?.withdrawalData.status === 2 ? color.blue.blue600 : splitWithdrawalData?.withdrawalData.status === 3 ? color.rose.rose600 : color.green.green600,
        },
        mbTransInfoProccessContainer: {
            backgroundColor: splitWithdrawalData?.withdrawalData.status === 2 ? color.blue.blue50 : splitWithdrawalData?.withdrawalData.status === 3 ? color.rose.rose50 : color.green.green50,
            width: '20vh', 
            border: `1px solid ${splitWithdrawalData?.withdrawalData.status === 2 ? color.blue.blue600 : splitWithdrawalData?.withdrawalData.status === 3 ? color.rose.rose600 : color.green.green600}`, 
            padding: 5, 
            paddingLeft: 10, 
            paddingRight: 10, 
            borderRadius: '40px', 
            color: splitWithdrawalData?.withdrawalData.status === 2 ? color.blue.blue600 : splitWithdrawalData?.withdrawalData.status === 3 ? color.rose.rose600 : color.green.green600,
        },
        showLessOrMoreContainer: {
            backgroundColor: color.slate.slate100, 
            cursor: 'pointer',
        },
    };

    const apiList = {
        getSplitwithdrawal: (tk: string) => {
            setBackDropOpen(true);
            WithdrawalApi.getSplitwithdrawal(tk).then(data => {
                setBackDropOpen(false);
                data?.splitWithdrawalData.forEach(item => {
                    if(item.isAllowUIComplete) {
                        return setIsAnyAllowUIComplete(true);
                    }
                })
                setSplitWithdrawalData(data);
                setHasRebate(data?.hasSubTransactionWithRebateAmount!);
                changeLanguageByCurrency(i18n, data.currencyCode);
            })
            .catch(err => {
                navigate("/error");
            });
        },
        confirmSplitwithdrawal: () => {
            WithdrawalApi.confirmSplitwithdrawal(selectedSplitWithdrawalOrderId, token).then((data) => {
                if(data.status === 200) {
                    setIsConfirmTransSuccess(true);
                    apiList.getSplitwithdrawal(token);
                } else {
                    setIsConfirmTransSuccess(false);
                }
                setIsAlertModalOpen(true);
            })
            .catch((err) => {
                setIsConfirmTransSuccess(false);
                setIsAlertModalOpen(true);
            })
        }
    }

    const actionList = {
        paymentDetailBtnOnClicked: (splitWithdrawalOrderId: string) => {
            setIsConfirmModalOpen(true);
            setSelectedSplitWithdrawalOrderId(splitWithdrawalOrderId);
        },
        handleConfirmModalCallback: () => {
            setIsConfirmModalOpen(false);
            apiList.confirmSplitwithdrawal();
        },
        handleAlertModalCallback: () => {
            setIsAlertModalOpen(false);
        },
        withdrawalStatusText: () => {
            let text: string = '';
            if(splitWithdrawalData?.withdrawalData.status === 2) {
                text = t('TransactionInProgress');
            } else if(splitWithdrawalData?.withdrawalData.status === 3) {
                text = t('WithdrawFailed');
            } else if(splitWithdrawalData?.withdrawalData.status === 4) {
                text = t('WithdrawCompleted');
            } else if(splitWithdrawalData?.withdrawalData.status === 5) {
                text = t('PartialComplete');
            }
            return text;
        },
        copyOrderId: () => {
            const orderId = splitWithdrawalData?.withdrawalData?.merchantTransId;
            if (orderId) {
                navigator.clipboard.writeText(orderId)
                    .then(() => {
                        setSnackBarOpen(true);
                    });
            }
        },
        handleSnackBarClose: () => {
            setSnackBarOpen(false);
        },
    }

    return (
        <>
            <Box sx={{ flexGrow: 1 }} minWidth={!isMobile ? '150vh' : '320px'} mb={!isMobile ? 5 : 0}>
                <Grid container>
                    <Grid container flexDirection="row" sx={styles.headerContainer}>
                        <Grid item width={'100%'} ml={!isMobile ? '25vh' : 0} mr={!isMobile ? '25vh' : 0}>
                            <Grid container justifyContent="center" flexDirection="column" >
                                <Typography variant="h5" mt={2}>
                                    {t('WithdrawTitle')}
                                </Typography>
                                <Divider style={styles.headerDivider} color={'#65a1c2'} />
                                <Typography variant="body2" color={color.sky.sky200} mb={2}>
                                    {t('WithdrawSubTitle')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    {
                        isAnyAllowUIComplete && 
                        <Grid container flexDirection="row" p={2} width={'100%'} ml={!isMobile ? '25vh' : 0} mr={!isMobile ? '25vh' : 0}>
                            <WarningMessageContainer text={t('CompleteOrderWithinTimeFrame')} hasRebate={hasRebate} rebatePercentage={splitWithdrawalData?.rebatePercentage}/>
                        </Grid>
                    }

                    {/* 订单资讯 */}
                    <Grid container flexDirection="row" p={2} width={'100%'} ml={!isMobile ? '25vh' : 0} mr={!isMobile ? '25vh' : 0}>
                        <Typography variant="body1" fontWeight={'bold'} mb={2}>
                            {t('OrderInfo')}
                        </Typography>
                        { !isMobile ?
                            // PC view
                            <Grid container flexDirection={'row'} border={`1px solid ${color.slate.slate300}`} p={2} borderRadius={'12px'} >
                                <Grid item sm={12} md={12} mb={1}>
                                    <Typography variant='body2' fontWeight={'bold'}>
                                        <span style={{marginRight: 10}}>{formatDate(splitWithdrawalData?.withdrawalData!.submittedDateTime!)}</span>
                                        <span>{splitWithdrawalData?.withdrawalData.merchantTransId}</span>
                                        <span onClick={() => actionList.copyOrderId()}>
                                            <FileCopyIcon sx={styles.transInfoIcon} />
                                        </span>
                                    </Typography>
                                </Grid>
                                <Grid item sm={12} md={12}>
                                    <Grid container justifyContent={'space-between'} alignItems={'center'}>
                                        <Grid item sm={5} md={5}>
                                            <Grid container flexDirection={'row'} alignItems={'center'}>
                                                <Typography variant='body2' mr={2} color={color.slate.slate500}>
                                                    <span>{t('TotalAmount')}</span>
                                                </Typography>
                                                <Typography variant='h6' mr={3} fontWeight={'bold'}>
                                                    <span>{getCurrencySymbol(splitWithdrawalData?.currencyCode! || 'CNY') + formatNumberWithCommas(splitWithdrawalData?.withdrawalData.totalAmount! || 0)}</span>
                                                </Typography>
                                                <Typography variant='body2'>
                                                    <span style={styles.transInfoProccessContainer}>
                                                        {actionList.withdrawalStatusText()}
                                                    </span>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item sm={7} md={7}>
                                            <Grid container flexDirection={'row'} justifyContent={'flex-end'}>
                                                <Grid sm={4} md={4} item>
                                                    <Grid container justifyContent={'space-around'} alignItems={'center'}>
                                                        <Typography variant='body2' color={color.slate.slate500}>
                                                            <HistoryOutlinedIcon sx={styles.transInfoIcon} />
                                                            <span>{t('ProccessingAmount')}</span>
                                                        </Typography>
                                                        <Typography variant='body1' ml={1} fontWeight={'bold'}>
                                                            <span>{getCurrencySymbol(splitWithdrawalData?.currencyCode! || 'CNY') + formatNumberWithCommas(splitWithdrawalData?.withdrawalData.processingAmount! || 0)}</span>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid sm={4} md={4} item>
                                                    <Grid container justifyContent={'space-around'} alignItems={'center'}>
                                                        <Typography variant='body2' color={color.slate.slate500}>
                                                            <TollIcon sx={styles.transInfoIcon} />
                                                            <span>{t('ActualAmount')}</span>
                                                        </Typography>
                                                        <Typography variant='body1' ml={1} fontWeight={'bold'}>
                                                            <span>{getCurrencySymbol(splitWithdrawalData?.currencyCode! || 'CNY') + formatNumberWithCommas(splitWithdrawalData?.withdrawalData.completedAmount! || 0)}</span>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid sm={4} md={4} item>
                                                    <Grid container justifyContent={'space-around'} alignItems={'center'}>
                                                        <Typography variant='body2' color={color.slate.slate500}>
                                                            <WarningAmberIcon sx={styles.transInfoIcon} />
                                                            <span>{t('RejectAmount')}</span>
                                                        </Typography>
                                                        <Typography variant='body1' ml={1} fontWeight={'bold'}>
                                                            <span>{getCurrencySymbol(splitWithdrawalData?.currencyCode! || 'CNY') + formatNumberWithCommas(splitWithdrawalData?.withdrawalData.failedAmount! || 0)}</span>
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            :
                            // Mobile view
                            <Grid container flexDirection={'row'} justifyContent={'center'} border={`1px solid ${color.slate.slate300}`} p={2} borderRadius={'12px'}>
                                <Grid item xs={12} mb={1}>
                                    <Typography variant='body2' fontWeight={'bold'} textAlign={'center'}>
                                        <span>{formatDate(splitWithdrawalData?.withdrawalData!.submittedDateTime!)}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} mb={1}>
                                    <Typography variant='body2' fontWeight={'bold'} textAlign={'center'}>
                                        <span>{splitWithdrawalData?.withdrawalData.merchantTransId}</span>
                                        <span onClick={() => actionList.copyOrderId()}>
                                            <FileCopyIcon sx={styles.transInfoIcon} />
                                        </span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} mb={1}>
                                    <Typography variant='body2' textAlign={'center'} color={color.slate.slate500}>
                                        <span>{t('TotalAmount')}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} mb={2}>
                                    <Typography variant='h6' fontWeight={'bold'} textAlign={'center'}>
                                        <span>{getCurrencySymbol(splitWithdrawalData?.currencyCode! || 'CNY') + formatNumberWithCommas(splitWithdrawalData?.withdrawalData.totalAmount! || 0)}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} mb={2}>
                                    <Typography variant='body2' textAlign={'center'}>
                                        <span style={styles.mbTransInfoProccessContainer}>
                                            {actionList.withdrawalStatusText()}
                                        </span>
                                    </Typography>
                                </Grid>
                                <Grid item mb={1} xs={12}>
                                    <Grid container flexDirection={'row'} justifyContent={'center'} alignItems={'baseline'}>
                                        <Typography variant='body2' mr={3} color={color.slate.slate500}>
                                            <HistoryOutlinedIcon sx={styles.transInfoIcon} />
                                            <span>{t('ProccessingAmount')}</span>
                                        </Typography>
                                        <Typography variant='body1' fontWeight={'bold'}>
                                            <span>{getCurrencySymbol(splitWithdrawalData?.currencyCode! || 'CNY') + formatNumberWithCommas(splitWithdrawalData?.withdrawalData.processingAmount! || 0)}</span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item mb={1} xs={12}>
                                    <Grid container flexDirection={'row'} justifyContent={'center'} alignItems={'baseline'}>
                                        <Typography variant='body2' mr={3} color={color.slate.slate500}>
                                            <TollIcon sx={styles.transInfoIcon} />
                                            <span>{t('ActualAmount')}</span>
                                        </Typography>
                                        <Typography variant='body1' fontWeight={'bold'}>
                                            <span>{getCurrencySymbol(splitWithdrawalData?.currencyCode! || 'CNY') + formatNumberWithCommas(splitWithdrawalData?.withdrawalData.completedAmount! || 0)}</span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item mb={1} xs={12}>
                                    <Grid container flexDirection={'row'} justifyContent={'center'} alignItems={'baseline'}>
                                        <Typography variant='body2' mr={3} color={color.slate.slate500}>
                                            <WarningAmberIcon sx={styles.transInfoIcon} />
                                            <span>{t('RejectAmount')}</span>
                                        </Typography>
                                        <Typography variant='body1' fontWeight={'bold'}>
                                            <span>{getCurrencySymbol(splitWithdrawalData?.currencyCode! || 'CNY') + formatNumberWithCommas(splitWithdrawalData?.withdrawalData.failedAmount! || 0)}</span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>

                    {/* 拆账列表 */}
                    <Grid container flexDirection="row" width={'100%'} ml={!isMobile ? '25vh' : 0} mr={!isMobile ? '25vh' : 0} p={2}>
                        <Typography variant="body1" fontWeight={'bold'} mb={2}>
                            {t('SubWithList')}
                        </Typography>
                        {
                            splitWithdrawalData && splitWithdrawalData?.splitWithdrawalData.map((data, index) => {
                                return (
                                    <Grid container key={'summary' + index}>
                                        {
                                            index < 5 ? 
                                                (
                                                    <Grid mb={1} item width={'100%'}>
                                                        <SubPaymentDetailsCotainer 
                                                            status={data.status} 
                                                            amount={data.subWithdrawalAmount} 
                                                            isAllowUIComplete={data.isAllowUIComplete}
                                                            hasRebate={splitWithdrawalData.hasSubTransactionWithRebateAmount}
                                                            rebateAmount={data.rebateAmount}
                                                            currencyCode={splitWithdrawalData.currencyCode}
                                                            callBack={() => actionList.paymentDetailBtnOnClicked(data.splitWithdrawalOrderId)} 
                                                        />
                                                    </Grid>
                                                )
                                            : (index > 4 && isExpanded) ?
                                            (
                                                <Grid mb={1} item width={'100%'}>
                                                    <SubPaymentDetailsCotainer 
                                                        status={data.status} 
                                                        amount={data.subWithdrawalAmount} 
                                                        isAllowUIComplete={data.isAllowUIComplete}
                                                        hasRebate={splitWithdrawalData.hasSubTransactionWithRebateAmount}
                                                        rebateAmount={data.rebateAmount}
                                                        currencyCode={splitWithdrawalData.currencyCode}
                                                        callBack={() => actionList.paymentDetailBtnOnClicked(data.splitWithdrawalOrderId)}
                                                    />
                                                </Grid>
                                            )
                                            : null
                                        }
                                    </Grid>
                                )
                            })
                        }
                        {
                            (splitWithdrawalData && splitWithdrawalData!.splitWithdrawalData.length > 5 && !isExpanded) &&
                                <div style={{width: '100%'}} onClick={() => setIsExpanded(!isExpanded)}>
                                    <Grid container sx={styles.showLessOrMoreContainer} borderRadius={'10px'} p={1} justifyContent={'center'}>
                                        <Typography variant='body2' fontWeight={'bold'} color={color.slate.slate500}>
                                            {t('ShowMore')}({splitWithdrawalData!.splitWithdrawalData.length - 5})
                                        </Typography>
                                    </Grid>
                                </div>
                        }
                        {
                            (splitWithdrawalData && splitWithdrawalData!.splitWithdrawalData.length > 5 && isExpanded) &&
                                <div style={{width: '100%'}} onClick={() => setIsExpanded(!isExpanded)}>
                                    <Grid container sx={styles.showLessOrMoreContainer} borderRadius={'10px'} p={1} justifyContent={'center'}>
                                        <Typography variant='body2' fontWeight={'bold'} color={color.slate.slate500}>
                                            {t('ShowLess')}({splitWithdrawalData!.splitWithdrawalData.length - 5})
                                        </Typography>
                                    </Grid>
                                </div>
                        }
                    </Grid>
                </Grid>
            </Box>
            {isConfirmModalOpen && 
                <ConfirmTransactionModal
                    isOpen={isConfirmModalOpen} 
                    callBack={actionList.handleConfirmModalCallback} 
                    close={() => setIsConfirmModalOpen(false)} 
                />
            }
            {isAlertModalOpen && 
                <AlertModal 
                    isOpen={isAlertModalOpen} 
                    hasRebate={hasRebate}
                    isConfirmTransSuccess={isConfirmTransSuccess}
                    callBack={actionList.handleAlertModalCallback} 
                    close={() => setIsAlertModalOpen(false)} 
                />}
			{backDropOpen && (
				<SimpleBackDrop
					backDropOpen={backDropOpen}
					progressColor="primary"
					backgroundColor="#FFF"
				/>
			)}
            <Snackbar open={snackBarOpen} autoHideDuration={3000} onClose={actionList.handleSnackBarClose}>
                <Alert onClose={actionList.handleSnackBarClose} severity="success" sx={{ width: '100%' }}>
                    {t('OrderIdCopied')}
                </Alert>
            </Snackbar>
        </>
    );
}

// Default export
export default Withdrawal;
