import { useEffect, useState, ChangeEvent, useRef } from 'react';
import { Grid, Typography, TextField, Button, InputAdornment } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Local imports
import color from '../../styles/color';
import warningTriangle from '../../images/warningTriangle.png'
import { GetDepositResult } from '../../apiIntegration/models/deposit/getDeposit';
import { FileData } from '../../apiIntegration/models/deposit/confirmDeposit';
import { convertFileToBase64 } from '../../utils/format';

interface Attachment {
    name: string;
    file: File;
}

interface CNYPayProps {
    depositData: GetDepositResult;
    copyToClipBoard: (item: string) => void;
    confirmDeposit: (orderId: number, attachments: FileData[]) => void;
}

export const CNYPay: React.FC<CNYPayProps> = ({ depositData, copyToClipBoard, confirmDeposit }) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const MAX_ATTACHMENTS = 3;
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [attachments, setAttachments] = useState<Attachment[]>([]);
	const depositInfo: GetDepositResult = depositData;

    const styles = {
        copyContainer: {
            cursor: 'pointer', 
            backgroundColor: '#00ACE6', 
            padding: '3px', 
            paddingLeft: '15px', 
            paddingRight: '15px', 
            borderRadius: 25, 
            color: color.white
        },
        uploadDepositContainer: {
            border: '1px #000 solid', 
            width: !isMobile ? '70vh' : '100%', 
            maxWidth: '100%', 
            textAlign: 'center', 
            borderRadius: '4px', 
            padding: '10px', 
            cursor: 'pointer'
        },
        submitBtn: {
            width: !isMobile ? '70vh' : '100%',
            maxWidth: '100%', 
            backgroundColor: '#00ACE6'
        },
        attachmentTextContainer: {
            border: '1px #000 solid', 
            width: !isMobile ? '70vh' : '100%', 
            maxWidth: '100%', 
            borderRadius: '4px', 
            padding: '10px'
        },
        attachmentText: {
            width: '90%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            textDecoration: 'underline'
        }
    };

    const handleAddAttachment = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!files) return;

        if (attachments.length + files.length > MAX_ATTACHMENTS) {
            alert( t('YouCanSubmitMax', {maxAttachments: MAX_ATTACHMENTS}) );
            return;
        }
    
        const maxFileSizeMB = 4; // Example: max file size of 5 MB
        const validFiles: Attachment[] = [];
    
        Array.from(files).forEach((file) => {
            if (attachments.length + validFiles.length >= MAX_ATTACHMENTS) {
                return; // Stop adding files once the limit is reached
            }
            if (file.size > maxFileSizeMB * 1024 * 1024) {
                alert( t('FileExceedSize', {fileName: file.name, fileSize: maxFileSizeMB}));
            } else {
                validFiles.push({ name: file.name, file });
            }
        });
    
        if (validFiles.length) {
            setAttachments((prev) => [...prev, ...validFiles]);
        }
    
        // Clear the file input to allow re-uploading the same file if needed
        event.target.value = '';
    };

    const handleFileUploadClick = () => {
        fileInputRef.current?.click();
    };

    const removeAttachment = (index: number) => {
        setAttachments([...attachments.slice(0, index).concat(attachments.slice(index + 1))])
    };

    const calculateTimeLeft = () => {
        const targetTime = new Date(depositInfo.expiredDateTime).getTime(); // Target time in milliseconds
        const currentTime = new Date().getTime();
        const difference = targetTime - currentTime;
    
        if (difference > 0) {
            const totalSeconds = Math.floor(difference / 1000);
            const minutes = Math.floor(totalSeconds / 60);
            const seconds = totalSeconds % 60;
            return {
                minutes: String(minutes).padStart(2, '0'), // Ensure 2-digit format
                seconds: String(seconds).padStart(2, '0'),
            };
        } else {
            navigate("/expired");
            return { minutes: '00', seconds: '00' }; // Timer ends
        }
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearInterval(timer); // Cleanup interval on component unmount
    }, []);
    
    const preSubmitDeposit = async () => {
        try {
            const attachmentsBase64 = await Promise.all(
                attachments.map(async (attachment) => {
                    const base64 = await convertFileToBase64(attachment.file);
                    return { fileName: attachment.name, fileBytes: base64 };
                })
            );
            // Call the API with converted attachments
            confirmDeposit(depositInfo.orderId, attachmentsBase64);
        } catch (error) {
            console.error('Error converting files to Base64:', error);
        }
    };

    return (
        <Grid container justifyContent={'center'} alignItems={'stretch'} p={3} height={isMobile ? '100%' : 'auto'}>
            <Grid item>
                <div style={{backgroundColor: '#13CE66', padding: '3vh', borderRadius: 100}}> 
                    <DoneIcon sx={{color: '#FFF', fontSize: '60px'}} />
                </div>
            </Grid>
            <Grid item xs={12} md={12}>
                <Typography variant="h4" mt={2} sx={{textAlign: 'center'}}>
                    订单建立成功
                </Typography>
            </Grid>
            <Grid container justifyContent={'center'} alignItems={'center'} mt={2}>
                    <img src={warningTriangle} alt="warningTriangle" width={'20px'} style={{marginRight: 5}} />
                    {t('PaymentRemainingTime')}:
                    <span style={{color: '#F4212D', marginLeft: 5}}>{timeLeft.minutes}:{timeLeft.seconds}</span>
                </Grid>
            <Grid item xs={12} md={12} justifyItems={'center'}>
                <Grid container justifyContent={'center'}>
                    <Typography variant="body1" mt={2} textAlign={'start'} width={!isMobile ? '70vh' : '100%'} maxWidth={'100%'}>
                        {t('TransactionID')}
                    </Typography>
                </Grid>
                <Grid container justifyContent={'center'}>
                    <TextField
                        id="id"
                        sx={{width: !isMobile ? '70vh' : '100%' }}
                        value={depositInfo.merchantDepositId}
                        size='small'
                        InputProps={{
                            readOnly: true,
                            endAdornment: <InputAdornment position="end">
                                <span onClick={() => copyToClipBoard(depositInfo.merchantDepositId)} style={styles.copyContainer}>
                                    {t('Copy')}
                                </span>
                            </InputAdornment>,
                        }}
                        variant="standard"
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} justifyItems={'center'}>
                <Grid container justifyContent={'center'}>
                    <Typography variant="body1" mt={2} textAlign={'start'} width={!isMobile ? '70vh' : '100%'} maxWidth={'100%'}>
                        {t('Amount')}
                    </Typography>
                </Grid>
                <Grid container justifyContent={'center'}>
                    <TextField
                        id="id"
                        sx={{width: !isMobile ? '70vh' : '100%' }}
                        value={depositInfo.depositingAmount}
                        size='small'
                        InputProps={{
                            readOnly: true,
                            endAdornment: <InputAdornment position="end">
                                <span onClick={() => copyToClipBoard(depositInfo.depositingAmount)} style={styles.copyContainer}>
                                    {t('Copy')}
                                </span>
                            </InputAdornment>,
                        }}
                        variant="standard"
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} justifyItems={'center'}>
                <Grid container justifyContent={'center'}>
                    <Typography variant="body1" mt={2} textAlign={'start'} width={!isMobile ? '70vh' : '100%'} maxWidth={'100%'}>
                        {t('BankName')}
                    </Typography>
                </Grid>
                <Grid container justifyContent={'center'}>
                    <TextField
                        id="id"
                        sx={{width: !isMobile ? '70vh' : '100%' }}
                        value={depositInfo.depositingBankName}
                        size='small'
                        InputProps={{
                            readOnly: true,
                            endAdornment: <InputAdornment position="end">
                                <span onClick={() => copyToClipBoard(depositInfo.depositingBankName)} style={styles.copyContainer}>
                                    {t('Copy')}
                                </span>
                            </InputAdornment>,
                        }}
                        variant="standard"
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} justifyItems={'center'}>
                <Grid container justifyContent={'center'}>
                    <Typography variant="body1" mt={2} textAlign={'start'} width={!isMobile ? '70vh' : '100%'} maxWidth={'100%'}>
                        {t('AccountHolderName')}
                    </Typography>
                </Grid>
                <Grid container justifyContent={'center'}>
                    <TextField
                        id="id"
                        sx={{width: !isMobile ? '70vh' : '100%' }}
                        value={depositInfo.depositingAccountHolderName}
                        size='small'
                        InputProps={{
                            readOnly: true,
                            endAdornment: <InputAdornment position="end">
                                <span onClick={() => copyToClipBoard(depositInfo.depositingAccountHolderName)} style={styles.copyContainer}>
                                    {t('Copy')}
                                </span>
                            </InputAdornment>,
                        }}
                        variant="standard"
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} justifyItems={'center'}>
                <Grid container justifyContent={'center'}>
                    <Typography variant="body1" mt={2} textAlign={'start'} width={!isMobile ? '70vh' : '100%'} maxWidth={'100%'}>
                        {t('AccountNumber')}
                    </Typography>
                </Grid>
                <Grid container justifyContent={'center'}>
                    <TextField
                        id="id"
                        sx={{width: !isMobile ? '70vh' : '100%' }}
                        value={depositInfo.depositingAccountNumber}
                        size='small'
                        InputProps={{
                            readOnly: true,
                            endAdornment: <InputAdornment position="end">
                                <span onClick={() => copyToClipBoard(depositInfo.depositingAccountNumber)} style={styles.copyContainer}>
                                    {t('Copy')}
                                </span>
                            </InputAdornment>,
                        }}
                        variant="standard"
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} md={12} justifyItems={'center'}>
                <Grid container justifyContent={'center'}>
                    <Typography variant="body1" mt={2} textAlign={'start'} width={!isMobile ? '70vh' : '100%'} maxWidth={'100%'}>
                        {t('TransferSlips')} <span style={{color: '#F4212D'}}> *</span>（{t('TransferSlipDesc')}）
                    </Typography>
                </Grid>
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleAddAttachment}
                    multiple
                    accept="image/jpeg, image/png"
                    style={{ display: 'none' }}
                />
                {
                    attachments.length > 0 && 
                    attachments.map((attachment, index) => (
                        <Grid container justifyContent={'center'} key={index}>
                            <Grid container mb={attachments.length !== index + 1 ? 2 : 0} justifyContent={'space-between'} alignItems={'center'} style={styles.attachmentTextContainer}>
                                <span style={styles.attachmentText}>
                                    {attachment.name}
                                </span>
                                <CloseIcon onClick={() => removeAttachment(index)} sx={{fontSize: '18px', color: '#F4212D', cursor: 'pointer'}} />
                            </Grid>
                        </Grid>
                    ))
                }
                {
                    attachments.length < 3 && 
                    <Grid container justifyContent={'center'}>
                        <Grid container mt={attachments.length > 0 ? 2 : 0} onClick={handleFileUploadClick} justifyContent={'center'} alignItems={'center'} sx={styles.uploadDepositContainer}>
                            <AddCircleIcon sx={{color: '#00ACE6', fontSize: '18px', marginRight: 1}} />
                            {t('UploadProofOfDeposit')}
                        </Grid>
                    </Grid>
                }
            </Grid>
            <Grid item xs={12} md={12} mt={3} textAlign={'center'}>
                <Grid container justifyContent={'center'}>
                    <Button onClick={() => preSubmitDeposit()} disabled={attachments.length === 0} variant="contained" sx={styles.submitBtn}>{t('Submit')}</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

// Default export
export default CNYPay;
