import { useEffect, useState, ChangeEvent, useRef } from 'react';
import { Grid, Typography, Button, TextField, InputAdornment } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Local imports
import ThaiQRPaymentlogo from '../../images/Thai_QR_Payment_logo.png'
import promptPay from '../../images/prompt-pay.png'
import color from '../../styles/color';
import warningTriangle from '../../images/warningTriangle.png'
import { GetDepositResult } from '../../apiIntegration/models/deposit/getDeposit';
import { FileData } from '../../apiIntegration/models/deposit/confirmDeposit';
import { convertFileToBase64 } from '../../utils/format';

interface Attachment {
    name: string;
    file: File;
}

interface PromptPayProps {
    depositData: GetDepositResult;
    copyToClipBoard: (item: string) => void;
    confirmDeposit: (orderId: number, attachments: FileData[]) => void;
}

export const PromptPay: React.FC<PromptPayProps> = ({ depositData, copyToClipBoard, confirmDeposit }) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const MAX_ATTACHMENTS = 3;
    const fileInputRef = useRef<HTMLInputElement>(null);
    const depositInfo: GetDepositResult = depositData;
    const [attachments, setAttachments] = useState<Attachment[]>([]);
    
    const styles = {
        copyContainer: {
            cursor: 'pointer',
            backgroundColor: '#003F6E',
            padding: '3px',
            paddingLeft: '15px',
            paddingRight: '15px',
            borderRadius: 25,
            color: color.white
        },
        attachmentTextContainer: {
            border: '1px #000 solid',
            width: !isMobile ? '70vh' : '100%',
            borderRadius: '4px',
            padding: '10px'
        },
        attachmentText: {
            width: '90%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            textDecoration: 'underline'
        },
        uploadDepositContainer: {
            border: '1px #000 solid',
            width: !isMobile ? '70vh' : '100%',
            textAlign: 'center',
            borderRadius: '4px',
            padding: '10px',
            cursor: 'pointer'
        },
    };

    const handleFileUploadClick = () => {
        fileInputRef.current?.click();
    };

    const handleAddAttachment = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!files) return;

        if (attachments.length + files.length > MAX_ATTACHMENTS) {
            alert( t('YouCanSubmitMax', {maxAttachments: MAX_ATTACHMENTS}) );
            return;
        }

        const maxFileSizeMB = 4; // Example: max file size of 4 MB
        const validFiles: Attachment[] = [];

        Array.from(files).forEach((file) => {
            if (attachments.length + validFiles.length >= MAX_ATTACHMENTS) {
                return; // Stop adding files once the limit is reached
            }
            if (file.size > maxFileSizeMB * 1024 * 1024) {
                alert( t('FileExceedSize', {fileName: file.name, fileSize: maxFileSizeMB}));
            } else {
                validFiles.push({ name: file.name, file });
            }
        });

        if (validFiles.length) {
            setAttachments((prev) => [...prev, ...validFiles]);
        }

        // Clear the file input to allow re-uploading the same file if needed
        event.target.value = '';
    };

    const removeAttachment = (index: number) => {
        setAttachments([...attachments.slice(0, index).concat(attachments.slice(index + 1))])
    };

    const calculateTimeLeft = () => {
        const targetTime = new Date(depositInfo.expiredDateTime).getTime(); // Target time in milliseconds
        const currentTime = new Date().getTime();
        const difference = targetTime - currentTime;

        if (difference > 0) {
            const totalSeconds = Math.floor(difference / 1000);
            const minutes = Math.floor(totalSeconds / 60);
            const seconds = totalSeconds % 60;
            return {
                minutes: String(minutes).padStart(2, '0'), // Ensure 2-digit format
                seconds: String(seconds).padStart(2, '0'),
            };
        } else {
            navigate("/expired");
            return { minutes: '00', seconds: '00' }; // Timer ends
        }
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearInterval(timer); // Cleanup interval on component unmount
    }, []);

    const handleDownload = async () => {
        try {
            const response = await fetch(depositInfo.qrUrl);

            if (!response.ok) {
                throw new Error('Failed to fetch the image.');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'qr-code.jpg'; // Set the default file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Clean up the object URL
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the image:', error);
        }
    };

    const preSubmitDeposit = async () => {
        try {
            const attachmentsBase64 = await Promise.all(
                attachments.map(async (attachment) => {
                    const base64 = await convertFileToBase64(attachment.file);
                    return { fileName: attachment.name, fileBytes: base64 };
                })
            );
            // Call the API with converted attachments
            confirmDeposit(depositInfo.orderId, attachmentsBase64);
        } catch (error) {
            console.error('Error converting files to Base64:', error);
        }
    };

    return (
        <Grid container flexDirection={'column'} alignItems={'stretch'}>
            <Grid item sx={{ backgroundColor: '#003F6E' }}>
                <Grid container justifyContent={'center'} my={3}>
                    <img src={ThaiQRPaymentlogo} alt="Thai_QR_Payment_logo" width={'200vh'} />
                </Grid>
            </Grid>
            <Grid item px={isMobile ? 2 : 0}>
                <Grid container justifyContent={'center'} mt={3} mb={2}>
                    <img src={promptPay} alt="promptPay" width={'150vh'} />
                </Grid>
                <Grid container justifyContent={'center'} mb={1}>
                    <img src={depositInfo.qrUrl} width={!isMobile ? '20%' : '60%'} />
                </Grid>
                {
                    depositInfo.qrUrl && <Grid container justifyContent={'center'} mb={2}>
                        <Button onClick={handleDownload} variant="contained" sx={{ width: !isMobile ? '10%' : '50%', backgroundColor: '#003F6E' }}>{t('Download')}</Button>
                    </Grid>
                }
                <Grid container justifyContent={'center'} alignItems={'center'} mb={1}>
                    <img src={warningTriangle} alt="warningTriangle" width={'20px'} style={{ marginRight: 5 }} />
                    {t('PaymentRemainingTime')}:
                    <span style={{ color: '#F4212D', marginLeft: 5 }}>{timeLeft.minutes}:{timeLeft.seconds}</span>
                </Grid>
                <Grid container justifyContent={'center'} mb={2}>
                    <Grid item xs={12} md={12} justifyItems={'center'}>
                        <Grid container justifyContent={'center'}>
                            <Typography variant="body1" mt={2} width={!isMobile ? '70vh' : '100%'}>
                                {t('TransactionID')}
                            </Typography>
                        </Grid>
                        <Grid container justifyContent={'center'}>
                            <TextField
                                id="outlined-read-only-input"
                                size="small"
                                sx={{ width: !isMobile ? '70vh' : '100%' }}
                                value={depositInfo.merchantDepositId}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: <InputAdornment position="end">
                                        <span onClick={() => copyToClipBoard(depositInfo.merchantDepositId)} style={styles.copyContainer}>
                                            {t('Copy')}
                                        </span>
                                    </InputAdornment>,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} justifyItems={'center'}>
                        <Grid container justifyContent={'center'}>
                            <Typography variant="body1" mt={2} width={!isMobile ? '70vh' : '100%'}>
                                {t('Amount')} (THB)
                            </Typography>
                        </Grid>
                        <Grid container justifyContent={'center'}>
                            <TextField
                                id="outlined-read-only-input"
                                size="small"
                                sx={{ width: !isMobile ? '70vh' : '100%' }}
                                value={depositInfo.depositingAmount}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: <InputAdornment position="end">
                                        <span onClick={() => copyToClipBoard(depositInfo.depositingAmount)} style={styles.copyContainer}>
                                            {t('Copy')}
                                        </span>
                                    </InputAdornment>,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} justifyItems={'center'}>
                        <Grid container justifyContent={'center'}>
                            <Typography variant="body1" mt={2} width={!isMobile ? '70vh' : '100%'}>
                                {t('BankName')}
                            </Typography>
                        </Grid>
                        <Grid container justifyContent={'center'}>
                            <TextField
                                id="outlined-read-only-input"
                                size="small"
                                sx={{ width: !isMobile ? '70vh' : '100%' }}
                                value={depositInfo.depositingBankName}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: <InputAdornment position="end">
                                        <span onClick={() => copyToClipBoard(depositInfo.depositingBankName)} style={styles.copyContainer}>
                                            {t('Copy')}
                                        </span>
                                    </InputAdornment>,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} justifyItems={'center'}>
                        <Grid container justifyContent={'center'}>
                            <Typography variant="body1" mt={2} width={!isMobile ? '70vh' : '100%'}>
                                {t('AccountHolderName')}
                            </Typography>
                        </Grid>
                        <Grid container justifyContent={'center'}>
                            <TextField
                                id="outlined-read-only-input"
                                size="small"
                                sx={{ width: !isMobile ? '70vh' : '100%' }}
                                value={depositInfo.depositingAccountHolderName}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: <InputAdornment position="end">
                                        <span onClick={() => copyToClipBoard(depositInfo.depositingAccountHolderName)} style={styles.copyContainer}>
                                            {t('Copy')}
                                        </span>
                                    </InputAdornment>,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} justifyItems={'center'}>
                        <Grid container justifyContent={'center'}>
                            <Typography variant="body1" mt={2} width={!isMobile ? '70vh' : '100%'}>
                                {t('AccountNumber')}
                            </Typography>
                        </Grid>
                        <Grid container justifyContent={'center'}>
                            <TextField
                                id="outlined-read-only-input"
                                size="small"
                                sx={{ width: !isMobile ? '70vh' : '100%' }}
                                value={depositInfo.depositingAccountNumber}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: <InputAdornment position="end">
                                        <span onClick={() => copyToClipBoard(depositInfo.depositingAccountNumber)} style={styles.copyContainer}>
                                            {t('Copy')}
                                        </span>
                                    </InputAdornment>
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} justifyItems={'center'}>
                        <Grid container justifyContent={'center'}>
                            <Typography variant="body1" mt={2} width={!isMobile ? '70vh' : '100%'}>
                                {t('TransferSlips')} <span style={{ color: '#F4212D' }}> *</span> ( {t('TransferSlipDesc')} )
                            </Typography>
                        </Grid>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleAddAttachment}
                            multiple
                            accept="image/jpeg, image/png"
                            style={{ display: 'none' }}
                        />
                        {
                            attachments.length > 0 &&
                            attachments.map((attachment, index) => (
                                <Grid container justifyContent={'center'} key={index}>
                                    <Grid container mb={attachments.length !== index + 1 ? 2 : 0} justifyContent={'space-between'} alignItems={'center'} style={styles.attachmentTextContainer}>
                                        <span style={styles.attachmentText}>
                                            {attachment.name}
                                        </span>
                                        <CloseIcon onClick={() => removeAttachment(index)} sx={{ fontSize: '18px', color: '#F4212D', cursor: 'pointer' }} />
                                    </Grid>
                                </Grid>
                            ))
                        }
                        {
                            attachments.length < 3 &&
                            <Grid container justifyContent={'center'}>
                                <Grid container mt={attachments.length > 0 ? 2 : 0} onClick={handleFileUploadClick} justifyContent={'center'} alignItems={'center'} sx={styles.uploadDepositContainer}>
                                    <AddCircleIcon sx={{ color: '#003F6E', fontSize: '18px', marginRight: 1 }} />
                                    {t('UploadProofOfDeposit')}
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    <Grid item xs={12} md={12} mb={1} mt={2} justifyItems={'center'}>
                        <Grid container justifyContent={'center'}>
                            <Grid container width={!isMobile ? '70vh' : '100%'} justifyContent={isMobile ? 'space-around' : 'space-between'} alignItems={'center'}>
                                <Button onClick={() => preSubmitDeposit()} disabled={attachments.length === 0} variant="contained" sx={{ width: '100%', backgroundColor: '#003F6E' }}>{t('Submit')}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sx={{ backgroundColor: '#003F6E' }}>
                <Grid container flexDirection={'column'} px={isMobile ? 5 : 0} alignItems={'center'} mt={3} mb={3} sx={{ color: '#FFF', fontSize: '12px', textAlign: 'center' }}>
                    <Typography variant='caption' mb={1}>
                        <span>
                            ข้อควรจำ ในการสแกน QR..
                        </span>
                    </Typography>
                    <Typography variant='caption'>
                        <span>
                            - คิวอาร์โค้ด ใช้ได้เพียงครั้งเดียวเท่านั้น ห้ามใช้ซ้ำ
                        </span>
                    </Typography>
                    <Typography variant='caption'>
                        <span>
                            - หลังจากสร้างคิวอาร์โค้ด ต้องจ่ายภายใน 10 นาที
                        </span>
                    </Typography>
                    <Typography variant='caption'>
                        <div>
                            <span><DoneIcon sx={{ fontSize: '12px', color: '#22C55E' }} /></span>..เครดิตจะเข้าออโต้ ภายใน 10 นาที..<span><DoneIcon sx={{ fontSize: '12px', color: '#22C55E' }} /></span>
                        </div>
                    </Typography>

                </Grid>
            </Grid>
            <Grid item sx={{ backgroundColor: '#0F172A' }}>
                <Grid container flexDirection={'column'} px={isMobile ? 5 : 0} alignItems={'center'} mt={3} mb={3} sx={{ color: '#FDE047', fontSize: '12px', textAlign: 'center' }}>
                    <Typography variant='caption' mb={1}>
                        <span>
                            คำแนะนำสำคัญ
                        </span>
                    </Typography>
                    <Typography variant='caption'>
                        <span>
                            1. โปรดตรวจสอบว่าบัญชีธนาคารของคุณมีฟังก์ชั่นธนาคารออนไลน์หรือไม่
                        </span>
                    </Typography>
                    <Typography variant='caption'>
                        <span>
                            2. ปุ่มต่างๆ คลิกเพียงครั้งเดียว และห้ามคลิกอัพเดตหน้าเว็บ
                        </span>
                    </Typography>
                    <Typography variant='caption'>
                        <span>
                            3. ใช้เวลาไม่กี่นาทีในการฝากเงิน และระบบจะเติมเงินโดยอัตโนมัติ
                        </span>
                    </Typography>
                    <Typography variant='caption'>
                        <span>
                            4. หากมีปัญหา โปรดติดต่อฝ่ายบริการลูกค้าเพื่อขอความช่วยเหลือ
                        </span>
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

// Default export
export default PromptPay;
