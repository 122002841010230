import { useEffect, useState } from 'react';
import { Box, Snackbar, Alert } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Local imports
import * as DepositApi from '../../apiIntegration/depositApi';
import { GetDepositResult } from '../../apiIntegration/models/deposit/getDeposit';
import SimpleBackDrop from '../../components/backDrop/index';
import PromptPay from '../../components/deposit/promptPay';
import VietQR from '../../components/deposit/vietQR';
import CNYPay from '../../components/deposit/cnyPay';
import { FileData } from '../../apiIntegration/models/deposit/confirmDeposit';
import { changeLanguageByCurrency } from '../../utils/commonUtils';

type AlertColor = "success" | "info" | "warning" | "error"

function Deposit() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [token, setToken] = useState<string>('');
	const [backDropOpen, setBackDropOpen] = useState<boolean>(false);
    const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [currencyCode, setCurrencyCode] = useState<string>('');
	const [depositData, setDepositData] = useState<GetDepositResult>();
	const [alertText, setAlertText] = useState<string>('CopiedToClipboard');
	const [alertType, setAlertType] = useState<AlertColor>('success'); // "success" | "info" | "warning" | "error"

    useEffect(() => {
        // Get the current URL
        const url = window.location.href;
        // Create a new URL object
        const urlObj = new URL(url);
        // Get the search parameters
        const params = new URLSearchParams(urlObj.search);
        // Access the token parameter
        setToken(params.get('token')!);
        apiList.getDeposit(params.get('token')!);
	}, []);

    const apiList = {
        getDeposit: (tk: string) => {
            setBackDropOpen(true);
            DepositApi.getDeposit(tk).then(data => {
                setDepositData(data);
                setCurrencyCode(data.currencyCode);
                changeLanguageByCurrency(i18n, data.currencyCode);
            })
            .catch((err) => {
                if(err.response.status === 401) {
                    navigate("/expired", { replace: true });
                } else {
                    navigate("/error");
                }
            })
            .finally(() => {
                setBackDropOpen(false);
            });
        },
        confirmDeposit: (orderId: number, attachments: FileData[]) => {
            setBackDropOpen(true);
            DepositApi.confirmDeposit(token, orderId, attachments).then((data) => {
                if(data.status === 200) {
                    navigate("/success", { replace: true });
                } else {
                    setAlertText('SubmitFailed');
                    setAlertType('error');
                    setSnackBarOpen(true);
                }
            })
            .catch((err) => {
                setAlertText('SubmitFailed');
                setAlertType('error');
                setSnackBarOpen(true);
            })
            .finally(() => {
                setBackDropOpen(false);
            });
        }
    }

    const actionList = {
        copyOrderId: (item: string) => {
            if (item) {
                navigator.clipboard.writeText(item)
                .then(() => {
                    setAlertText('CopiedToClipboard');
                    setAlertType('success');
                    setSnackBarOpen(true);
                });
            }
        },
        handleSnackBarClose: () => {
            setSnackBarOpen(false);
        },
    }

    return (
        <>
            <Box sx={{ display: currencyCode === 'VND' ? 'flex' : ''}} minWidth={!isMobile && currencyCode !== 'VND' ? '150vh' : '320px'}>
                {
                    currencyCode === 'THB' ? 
                        <PromptPay 
                            depositData={depositData!} 
                            copyToClipBoard={(item) => actionList.copyOrderId(item)}
                            confirmDeposit={(orderId, attachments) => apiList.confirmDeposit(orderId, attachments)}
                        /> :
                    currencyCode === 'VND' ?
                        <VietQR 
                            depositData={depositData!} 
                            copyToClipBoard={(item) => actionList.copyOrderId(item)}
                            confirmDeposit={(orderId, attachments) => apiList.confirmDeposit(orderId, attachments)}
                        /> :
                    currencyCode === 'CNY' ?
                        <CNYPay 
                            depositData={depositData!} 
                            copyToClipBoard={(item) => actionList.copyOrderId(item)}
                            confirmDeposit={(orderId, attachments) => apiList.confirmDeposit(orderId, attachments)}
                        /> :
                    <></>
                }
            </Box>
			{backDropOpen && (
				<SimpleBackDrop
					backDropOpen={backDropOpen}
					progressColor="primary"
					backgroundColor="#FFF"
				/>
			)}
            <Snackbar 
                open={snackBarOpen} 
                autoHideDuration={3000} 
                onClose={actionList.handleSnackBarClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Alert onClose={actionList.handleSnackBarClose} severity={alertType} sx={{ width: '100%' }}>
                    {t(alertText)}
                </Alert>
            </Snackbar>
        </>
    );
}


// Default export
export default Deposit;
