import { useEffect, useState, useRef, ChangeEvent } from 'react';
import { Grid, Box, Typography, Button, TextField, InputAdornment } from '@mui/material';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Local imports
import color from '../../styles/color';
import phoneImg from '../../images/phoneImg.png'
import scanQr from '../../images/scanQr.png'
import step2QR from '../../images/step2QR.png'
import step3done from '../../images/step3done.png'
import warningTriangle from '../../images/warningTriangle.png'
import logoVietQr from '../../images/logoVietQr.png'
import napas from '../../images/napas.png'
import question from '../../images/question.png'
import vietQrBg_mb from '../../images/vietQrBg_mb.png'
import vietStep1_mb from '../../images/vietStep1_mb.png'
import vietStep2_mb from '../../images/vietStep2_mb.png'
import vietStep3_mb from '../../images/vietStep3_mb.png'
import vietStep4_mb from '../../images/vietStep4_mb.png'
import { GetDepositResult } from '../../apiIntegration/models/deposit/getDeposit';
import { FileData } from '../../apiIntegration/models/deposit/confirmDeposit';
import { convertFileToBase64 } from '../../utils/format';

interface Attachment {
    name: string;
    file: File;
}

interface VietQRProps {
    depositData: GetDepositResult;
    copyToClipBoard: (item: string) => void;
    confirmDeposit: (orderId: number, attachments: FileData[]) => void;
}

export const VietQR: React.FC<VietQRProps> = ({ depositData, copyToClipBoard, confirmDeposit }) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const fileInputRef = useRef<HTMLInputElement>(null);
    const MAX_ATTACHMENTS = 3;
    const vietQrBgRef = useRef<HTMLImageElement>(null); // Specify the type of the element
    const [attachments, setAttachments] = useState<Attachment[]>([]);
    const depositInfo: GetDepositResult = depositData;

    const styles = {
        copyContainer: {
            cursor: 'pointer',
            backgroundColor: '#226BF4',
            padding: '3px',
            paddingLeft: '15px',
            paddingRight: '15px',
            borderRadius: 25,
            color: color.white
        },
        attachmentTextContainer: {
            border: '1px #000 solid',
            width: !isMobile ? '70vh' : '100%',
            maxWidth: '100%',
            borderRadius: '4px',
            padding: '10px',
            cursor: 'pointer'
        },
        uploadDepositContainer: {
            border: '1px #000 solid',
            width: !isMobile ? '70vh' : '100%',
            maxWidth: '100%',
            textAlign: 'center',
            borderRadius: '4px',
            padding: '10px',
            cursor: 'pointer'
        }
    };

    const tutorialOnClicked = () => {
        if (vietQrBgRef.current) {
            vietQrBgRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    const handleFileUploadClick = () => {
        fileInputRef.current?.click();
    };

    const handleAddAttachment = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!files) return;

        if (attachments.length + files.length > MAX_ATTACHMENTS) {
            alert( t('YouCanSubmitMax', {maxAttachments: MAX_ATTACHMENTS}) );
            return;
        }

        const maxFileSizeMB = 4; // Example: max file size of 4 MB
        const validFiles: Attachment[] = [];

        Array.from(files).forEach((file) => {
            if (attachments.length + validFiles.length >= MAX_ATTACHMENTS) {
                return; // Stop adding files once the limit is reached
            }
            if (file.size > maxFileSizeMB * 1024 * 1024) {
                alert( t('FileExceedSize', {fileName: file.name, fileSize: maxFileSizeMB}));
            } else {
                validFiles.push({ name: file.name, file });
            }
        });

        if (validFiles.length) {
            setAttachments((prev) => [...prev, ...validFiles]);
        }

        // Clear the file input to allow re-uploading the same file if needed
        event.target.value = '';
    };

    const removeAttachment = (index: number) => {
        setAttachments([...attachments.slice(0, index).concat(attachments.slice(index + 1))])
    }

    const calculateTimeLeft = () => {
        const targetTime = new Date(depositInfo.expiredDateTime).getTime(); // Target time in milliseconds
        const currentTime = new Date().getTime();
        const difference = targetTime - currentTime;

        if (difference > 0) {
            const totalSeconds = Math.floor(difference / 1000);
            const minutes = Math.floor(totalSeconds / 60);
            const seconds = totalSeconds % 60;
            return {
                minutes: String(minutes).padStart(2, '0'), // Ensure 2-digit format
                seconds: String(seconds).padStart(2, '0'),
            };
        } else {
            navigate("/expired");
            return { minutes: '00', seconds: '00' }; // Timer ends
        }
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearInterval(timer); // Cleanup interval on component unmount
    }, []);

    const handleDownload = async () => {
        try {
            const response = await fetch(depositInfo.qrUrl);

            if (!response.ok) {
                throw new Error('Failed to fetch the image.');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'qr-code.jpg'; // Set the default file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Clean up the object URL
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the image:', error);
        }
    };

    const preSubmitDeposit = async () => {
        try {
            const attachmentsBase64 = await Promise.all(
                attachments.map(async (attachment) => {
                    const base64 = await convertFileToBase64(attachment.file);
                    return { fileName: attachment.name, fileBytes: base64 };
                })
            );
            // Call the API with converted attachments
            confirmDeposit(depositInfo.orderId, attachmentsBase64);
        } catch (error) {
            console.error('Error converting files to Base64:', error);
        }
    };

    return (
        !isMobile ? (
            // PC View
            <Grid container alignItems="center">
                {/* Left Side: QR Code Section */}
                <Grid item xs={6} md={6} p={3}>
                    <Box sx={{ textAlign: 'center' }}>
                        <div style={{ marginBottom: '10px' }}>
                            <img src={logoVietQr} alt="logoVietQr" width={'20%'} />
                        </div>
                        {/* Placeholder for QR Code */}
                        <Grid
                            container
                            sx={{
                                width: '40%',
                                margin: '0 auto',
                            }}
                        >
                            <img src={depositInfo.qrUrl} width={'100%'} />
                        </Grid>
                        <div style={{ marginTop: '10px' }}>
                            <img src={napas} alt="napas" width={'20%'} />
                        </div>
                        {
                            depositInfo.qrUrl && 
                            <Grid container justifyContent={'center'} my={2}>
                                <Button onClick={handleDownload} variant="contained" sx={{ width: !isMobile ? '30%' : '50%', backgroundColor: '#226BF4' }}>{t('Download')}</Button>
                            </Grid>
                        }
                        <Grid container justifyContent={'center'} alignItems={'center'} my={2}>
                            <img src={warningTriangle} alt="warningTriangle" width={'20px'} style={{ marginRight: 5 }} />
                            {t('PaymentRemainingTime')}:
                            <span style={{ color: '#F4212D', marginLeft: 5 }}>{timeLeft.minutes}:{timeLeft.seconds}</span>
                        </Grid>
                        <Grid container justifyContent={'center'} mb={2}>
                            <Grid item xs={12} md={12} justifyItems={'center'}>
                                <Grid container justifyContent={'center'}>
                                    <Typography variant="body1" mt={1} textAlign={'start'} width={!isMobile ? '70vh' : '100%'} maxWidth={'100%'}>
                                        {t('TransactionID')}
                                    </Typography>
                                </Grid>

                                <Grid container justifyContent={'center'}>
                                    <TextField
                                        id="outlined-read-only-input"
                                        size="small"
                                        sx={{ width: !isMobile ? '70vh' : '100%' }}
                                        value={depositInfo.merchantDepositId}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: <InputAdornment position="end">
                                                <span onClick={() => copyToClipBoard(depositInfo.merchantDepositId)} style={styles.copyContainer}>
                                                    {t('Copy')}
                                                </span>
                                            </InputAdornment>,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} justifyItems={'center'}>
                                <Grid container justifyContent={'center'}>
                                    <Typography variant="body1" mt={1} textAlign={'start'} width={!isMobile ? '70vh' : '100%'} maxWidth={'100%'}>
                                        {t('Amount')} (VND)
                                    </Typography>
                                </Grid>
                                <Grid container justifyContent={'center'}>
                                    <TextField
                                        id="outlined-read-only-input"
                                        size="small"
                                        sx={{ width: !isMobile ? '70vh' : '100%' }}
                                        value={depositInfo.depositingAmount}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: <InputAdornment position="end">
                                                <span onClick={() => copyToClipBoard(depositInfo.depositingAmount)} style={styles.copyContainer}>
                                                    {t('Copy')}
                                                </span>
                                            </InputAdornment>,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} justifyItems={'center'}>
                                <Grid container justifyContent={'center'}>
                                    <Typography variant="body1" mt={1} textAlign={'start'} width={!isMobile ? '70vh' : '100%'} maxWidth={'100%'}>
                                        {t('BankName')}
                                    </Typography>
                                </Grid>
                                <Grid container justifyContent={'center'}>
                                    <TextField
                                        id="outlined-read-only-input"
                                        size="small"
                                        sx={{ width: !isMobile ? '70vh' : '100%' }}
                                        value={depositInfo.depositingBankName}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: <InputAdornment position="end">
                                                <span onClick={() => copyToClipBoard(depositInfo.depositingBankName)} style={styles.copyContainer}>
                                                    {t('Copy')}
                                                </span>
                                            </InputAdornment>,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} justifyItems={'center'}>
                                <Grid container justifyContent={'center'}>
                                    <Typography variant="body1" mt={1} textAlign={'start'} width={!isMobile ? '70vh' : '100%'} maxWidth={'100%'}>
                                        {t('AccountHolderName')}
                                    </Typography>
                                </Grid>
                                <Grid container justifyContent={'center'}>
                                    <TextField
                                        id="outlined-read-only-input"
                                        size="small"
                                        sx={{ width: !isMobile ? '70vh' : '100%' }}
                                        value={depositInfo.depositingAccountHolderName}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: <InputAdornment position="end">
                                                <span onClick={() => copyToClipBoard(depositInfo.depositingAccountHolderName)} style={styles.copyContainer}>
                                                    {t('Copy')}
                                                </span>
                                            </InputAdornment>,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} justifyItems={'center'}>
                                <Grid container justifyContent={'center'}>
                                    <Typography variant="body1" mt={1} textAlign={'start'} width={!isMobile ? '70vh' : '100%'} maxWidth={'100%'}>
                                        {t('AccountNumber')}
                                    </Typography>
                                </Grid>
                                <Grid container justifyContent={'center'}>
                                    <TextField
                                        id="outlined-read-only-input"
                                        size="small"
                                        sx={{ width: !isMobile ? '70vh' : '100%' }}
                                        value={depositInfo.depositingAccountNumber}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: <InputAdornment position="end">
                                                <span onClick={() => copyToClipBoard(depositInfo.depositingAccountNumber)} style={styles.copyContainer}>
                                                    {t('Copy')}
                                                </span>
                                            </InputAdornment>,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} justifyItems={'center'}>
                                <Grid container justifyContent={'center'}>
                                    <Typography variant="body1" mt={1} textAlign={'start'} width={!isMobile ? '70vh' : '100%'} maxWidth={'100%'}>
                                        {t('TransferSlips')} <span style={{ color: '#F4212D' }}> *</span> ( {t('TransferSlipDesc')} )
                                    </Typography>
                                </Grid>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    onChange={handleAddAttachment}
                                    multiple
                                    accept="image/jpeg, image/png"
                                    style={{ display: 'none' }}
                                />
                                {
                                    attachments.length > 0 &&
                                    attachments.map((attachment, index) => (
                                        <Grid container justifyContent={'center'} key={index}>
                                            <Grid container mb={attachments.length !== index + 1 ? 2 : 0} justifyContent={'space-between'} alignItems={'center'} style={styles.attachmentTextContainer}>
                                                <span style={{
                                                    width: '90%',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    textDecoration: 'underline',
                                                    textAlign: 'start',
                                                }}>
                                                    {attachment.name}
                                                </span>
                                                <CloseIcon onClick={() => removeAttachment(index)} sx={{ fontSize: '18px', color: '#F4212D', cursor: 'pointer' }} />
                                            </Grid>
                                        </Grid>
                                    ))
                                }
                                {
                                    attachments.length < 3 &&
                                    <Grid container justifyContent={'center'}>
                                        <Grid container mt={attachments.length > 0 ? 2 : 0} onClick={handleFileUploadClick} justifyContent={'center'} alignItems={'center'} style={{ border: '1px #000 solid', width: !isMobile ? '70vh' : '100%', maxWidth: '100%', textAlign: 'center', borderRadius: '4px', padding: '10px', cursor: 'pointer' }}>
                                            <AddCircleIcon sx={{ color: '#226BF4', fontSize: '18px', marginRight: 1 }} />
                                            {t('UploadProofOfDeposit')}
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                            <Grid item xs={12} md={12} mb={1} mt={2} justifyItems={'center'}>
                                <Grid container justifyContent={'center'}>
                                    <Grid container width={!isMobile ? '70vh' : '100%'} maxWidth={'100%'} justifyContent={isMobile ? 'space-around' : 'space-between'} alignItems={'center'}>
                                        <Button onClick={() => preSubmitDeposit()} disabled={attachments.length === 0} variant="contained" sx={{ width: '100%', backgroundColor: '#226BF4' }}>{t('Submit')}</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container width={!isMobile ? '70vh' : '100%'} maxWidth={'100%'} mt={3} justifySelf={'center'} alignItems={'stretch'}>
                            <Grid item xs={2} alignContent={'center'} sx={{ backgroundColor: '#E11D48', color: color.white, borderRadius: '4px 0px 0px 4px' }}>
                                <div>
                                    <WarningRoundedIcon />
                                </div>
                                <Typography variant='body2'>
                                    Chú ý
                                </Typography>
                            </Grid>
                            <Grid item xs={10} p={1} sx={{ backgroundColor: '#FFF1F2', color: '#E11D48', borderRadius: '0px 4px 4px 0px', textAlign: 'start' }}>
                                <Typography variant='caption' style={{ lineHeight: 1.2 }}>
                                    1、Vui lòng điền nội dung chuyển khoản, nếu không sẽ không thể cộng điểm. <br />
                                </Typography>
                                <Typography variant='caption' style={{ lineHeight: 1.2 }}>
                                    2、Mỗi mã QR chỉ dùng 1 lần, vui lòng quét đúng mã của giao dịch khi thực hiện lệnh và không lưu lại, xin cám ơn.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                {/* Right Side: Steps Section */}
                <Grid item xs={6} md={6} height={'100%'}>
                    <Box
                        sx={{
                            backgroundColor: '#F1F5F9',
                            textAlign: 'center',
                            height: '100%',
                            alignContent: 'center',
                        }}
                    >
                        <img src={phoneImg} alt="phoneImg" width={'50%'} />
                        <Grid container mt={5} mb={2} justifyContent={'center'}>
                            {/* Steps */}
                            <Grid item xs={3} md={2} mx={2}>
                                <Typography variant="body1">
                                    <span style={{ backgroundColor: '#226BF4', color: '#FFF', padding: '5px', paddingLeft: '10px', paddingRight: '10px', borderRadius: 20 }}>
                                        STEP 1
                                    </span>
                                </Typography>
                            </Grid>
                            <Grid item xs={3} md={2} mx={2}>
                                <Typography variant="body1">
                                    <span style={{ backgroundColor: '#226BF4', color: '#FFF', padding: '5px', paddingLeft: '10px', paddingRight: '10px', borderRadius: 20 }}>
                                        STEP 2
                                    </span>
                                </Typography>
                            </Grid>
                            <Grid item xs={3} md={2} mx={2}>
                                <Typography variant="body1">
                                    <span style={{ backgroundColor: '#226BF4', color: '#FFF', padding: '5px', paddingLeft: '10px', paddingRight: '10px', borderRadius: 20 }}>
                                        STEP 3
                                    </span>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent={'center'}>
                            {/* Steps */}
                            <Grid item xs={3} md={2} mx={2} >
                                <Typography variant="body2">
                                    Mở QRPay trên Mobile Banking
                                </Typography>
                            </Grid>
                            <Grid item xs={3} md={2} mx={2}>
                                <Typography variant="body2">
                                    Quét mã QR
                                </Typography>
                            </Grid>
                            <Grid item xs={3} md={2} mx={2}>
                                <Typography variant="body2">
                                    Xác nhận số tiền, hoàn tất thanh toán
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent={'center'} mt={2}>
                            {/* Steps */}
                            <Grid item xs={3} md={2} mx={2} >
                                <img src={scanQr} alt="scanQr" width={'70%'} />
                            </Grid>
                            <Grid item xs={3} md={2} mx={2}>
                                <img src={step2QR} alt="step2QR" width={'70%'} />
                            </Grid>
                            <Grid item xs={3} md={2} mx={2}>
                                <img src={step3done} alt="step3done" width={'70%'} />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent={'center'} alignItems={'center'} mt={5}>
                            <img src={warningTriangle} alt="warningTriangle" width={'15px'} />
                            <span style={{ color: '#E11D48', marginRight: 10, marginLeft: 10 }}>
                                Không lặp lại mã quét
                            </span>
                            <img src={warningTriangle} alt="warningTriangle" width={'15px'} />
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        ) : (
            // Mobile View
            <Grid container>
                <Grid item xs={12} md={12} p={3}>
                    <Box sx={{ textAlign: 'center' }} py={3}>
                        <div style={{ marginBottom: '3vh' }}>
                            <img src={logoVietQr} alt="logoVietQr" width={'40%'} />
                        </div>
                        {/* Placeholder for QR Code */}
                        <Grid
                            container
                            sx={{
                                width: '60%',
                                margin: '0 auto',
                            }}
                        >
                            <img src={depositInfo.qrUrl} width={'100%'} />
                        </Grid>
                        <div style={{ marginTop: depositInfo.qrUrl ? '3vh' : '0' }}>
                            <img src={napas} alt="napas" width={'30%'} />
                        </div>
                        {
                            depositInfo.qrUrl && 
                            <Grid container justifyContent={'center'} my={2}>
                                <Button onClick={handleDownload} variant="contained" sx={{ width: !isMobile ? '30%' : '50%', backgroundColor: '#226BF4' }}>{t('Download')}</Button>
                            </Grid>
                        }
                        <Grid container justifyContent={'center'} alignItems={'center'} my={2}>
                            <img src={warningTriangle} alt="warningTriangle" width={'20px'} style={{ marginRight: 5 }} />
                            {t('PaymentRemainingTime')}:
                            <span style={{ color: '#F4212D', marginLeft: 5 }}>{timeLeft.minutes}:{timeLeft.seconds}</span>
                        </Grid>
                        <Grid container justifyContent={'center'} mb={2}>
                            <Grid item xs={12} md={12} justifyItems={'center'}>
                                <Typography variant="body1" mt={1} textAlign={'start'} width={!isMobile ? '70vh' : '100%'} maxWidth={'100%'}>
                                    {t('TransactionID')}
                                </Typography>
                                <Grid container justifyContent={'center'}>
                                    <TextField
                                        id="outlined-read-only-input"
                                        size="small"
                                        sx={{ width: !isMobile ? '70vh' : '100%' }}
                                        value={depositInfo.merchantDepositId}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: <InputAdornment position="end">
                                                <span onClick={() => copyToClipBoard(depositInfo.merchantDepositId)} style={styles.copyContainer}>
                                                    {t('Copy')}
                                                </span>
                                            </InputAdornment>,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} justifyItems={'center'}>
                                <Typography variant="body1" mt={1} textAlign={'start'} width={!isMobile ? '70vh' : '100%'} maxWidth={'100%'}>
                                    {t('Amount')} (VND)
                                </Typography>
                                <Grid container justifyContent={'center'}>
                                    <TextField
                                        id="outlined-read-only-input"
                                        size="small"
                                        sx={{ width: !isMobile ? '70vh' : '100%' }}
                                        value={depositInfo.depositingAmount}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: <InputAdornment position="end">
                                                <span onClick={() => copyToClipBoard(depositInfo.depositingAmount)} style={styles.copyContainer}>
                                                    {t('Copy')}
                                                </span>
                                            </InputAdornment>,
                                        }}
                                    />
                                </Grid>

                            </Grid>
                            <Grid item xs={12} md={12} justifyItems={'center'}>
                                <Typography variant="body1" mt={1} textAlign={'start'} width={!isMobile ? '70vh' : '100%'} maxWidth={'100%'}>
                                    {t('BankName')}
                                </Typography>
                                <Grid container justifyContent={'center'}>
                                    <TextField
                                        id="outlined-read-only-input"
                                        size="small"
                                        sx={{ width: !isMobile ? '70vh' : '100%' }}
                                        value={depositInfo.depositingBankName}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: <InputAdornment position="end">
                                                <span onClick={() => copyToClipBoard(depositInfo.depositingBankName)} style={styles.copyContainer}>
                                                    {t('Copy')}
                                                </span>
                                            </InputAdornment>,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} justifyItems={'center'}>
                                <Typography variant="body1" mt={1} textAlign={'start'} width={!isMobile ? '70vh' : '100%'} maxWidth={'100%'}>
                                    {t('AccountHolderName')}
                                </Typography>
                                <Grid container justifyContent={'center'}>
                                    <TextField
                                        id="outlined-read-only-input"
                                        size="small"
                                        sx={{ width: !isMobile ? '70vh' : '100%' }}
                                        value={depositInfo.depositingAccountHolderName}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: <InputAdornment position="end">
                                                <span onClick={() => copyToClipBoard(depositInfo.depositingAccountHolderName)} style={styles.copyContainer}>
                                                    {t('Copy')}
                                                </span>
                                            </InputAdornment>,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} justifyItems={'center'}>
                                <Typography variant="body1" mt={1} textAlign={'start'} width={!isMobile ? '70vh' : '100%'} maxWidth={'100%'}>
                                    {t('AccountNumber')}
                                </Typography>
                                <Grid container justifyContent={'center'}>
                                    <TextField
                                        id="outlined-read-only-input"
                                        size="small"
                                        sx={{ width: !isMobile ? '70vh' : '100%' }}
                                        value={depositInfo.depositingAccountNumber}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: <InputAdornment position="end">
                                                <span onClick={() => copyToClipBoard(depositInfo.depositingAccountNumber)} style={styles.copyContainer}>
                                                    {t('Copy')}
                                                </span>
                                            </InputAdornment>,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} justifyItems={'center'}>
                                <Typography variant="body1" mt={1} textAlign={'start'} width={!isMobile ? '70vh' : '100%'} maxWidth={'100%'}>
                                    {t('TransferSlips')} <span style={{ color: '#F4212D' }}> *</span> ( {t('TransferSlipDesc')} )
                                </Typography>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    onChange={handleAddAttachment}
                                    multiple
                                    accept="image/jpeg, image/png"
                                    style={{ display: 'none' }}
                                />
                                {
                                    attachments.length > 0 &&
                                    attachments.map((attachment, index) => (
                                        <Grid key={index} container mb={attachments.length !== index + 1 ? 2 : 0} justifyContent={'space-between'} alignItems={'center'} sx={styles.attachmentTextContainer}>
                                            <span style={{
                                                width: '90%',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                textDecoration: 'underline',
                                                textAlign: 'start',
                                            }}>
                                                {attachment.name}
                                            </span>
                                            <CloseIcon onClick={() => removeAttachment(index)} sx={{ fontSize: '18px', color: '#F4212D', cursor: 'pointer' }} />
                                        </Grid>
                                    ))
                                }
                                {
                                    attachments.length < 3 &&
                                    <Grid container mt={attachments.length > 0 ? 2 : 0} onClick={handleFileUploadClick} justifyContent={'center'} alignItems={'center'} sx={styles.uploadDepositContainer}>
                                        <AddCircleIcon sx={{ color: '#226BF4', fontSize: '18px', marginRight: 1 }} />
                                        {t('UploadProofOfDeposit')}
                                    </Grid>
                                }
                            </Grid>
                            <Grid item xs={12} md={12} mb={1} mt={2} justifyItems={'center'}>
                                <Grid container width={!isMobile ? '70vh' : '100%'} maxWidth={'100%'} justifyContent={isMobile ? 'space-around' : 'space-between'} alignItems={'center'}>
                                    <Button onClick={() => preSubmitDeposit()} disabled={attachments.length === 0} variant="contained" sx={{ width: '100%', backgroundColor: '#226BF4' }}>{t('Submit')}</Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container justifyContent={'center'} alignItems={'center'} my={'2vh'}>
                            <Typography variant='body1' mr={1}>
                                <span onClick={() => tutorialOnClicked()} style={{ color: color.blue.blue500 }}>
                                    Cách quét mã QR
                                </span>
                            </Typography>
                            <img onClick={() => tutorialOnClicked()} src={question} alt="napas" width={'14px'} />
                        </Grid>
                        <Grid container width={!isMobile ? '70vh' : '100%'} maxWidth={'100%'} mt={3} justifySelf={'center'} alignItems={'stretch'}>
                            <Grid item xs={2} alignContent={'center'} sx={{ backgroundColor: '#E11D48', color: color.white, borderRadius: '4px 0px 0px 4px' }}>
                                <div>
                                    <WarningRoundedIcon />
                                </div>
                                <Typography variant='body2'>
                                    Chú ý
                                </Typography>
                            </Grid>
                            <Grid item xs={10} p={1} sx={{ backgroundColor: '#FFF1F2', color: '#E11D48', borderRadius: '0px 4px 4px 0px', textAlign: 'start' }}>
                                <Typography variant='body2' style={{ lineHeight: 1.2 }}>
                                    1、Vui lòng điền nội dung chuyển khoản, nếu không sẽ không thể cộng điểm. <br />
                                </Typography>
                                <Typography variant='body2' style={{ lineHeight: 1.2 }}>
                                    2、Mỗi mã QR chỉ dùng 1 lần, vui lòng quét đúng mã của giao dịch khi thực hiện lệnh và không lưu lại, xin cám ơn.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Grid container sx={{ backgroundColor: '#F1F5F9' }} style={{ position: 'relative' }}>
                        <Grid item xs={12} style={{ position: 'relative' }}>
                            <img ref={vietQrBgRef} src={vietQrBg_mb} alt="vietQrBg_mb" width={'100%'} />
                            <div style={{ textAlign: 'center', position: 'absolute', top: '75%', width: '100%', color: '#226BF4', font: 'oblique normal bold 30px/22px Helvetica' }}>
                                Cách quét mã QR
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={6} textAlign={'center'} mb={5}>
                                    <Grid container flexDirection={'column'} alignItems={'center'}>
                                        <Typography variant="body1" sx={{ zIndex: 1 }}>
                                            <span style={{ backgroundColor: '#226BF4', color: '#FFF', padding: '1vh', paddingLeft: '2vh', paddingRight: '2vh', borderRadius: 20 }}>
                                                STEP 1
                                            </span>
                                        </Typography>
                                        <img src={vietStep1_mb} alt="vietStep1_mb" width={'100vh'} />
                                        <Typography variant="body1" mt={'2vh'} sx={{ zIndex: 1 }}>
                                            Lưu mã QR
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} textAlign={'center'}>
                                    <Grid container flexDirection={'column'} alignItems={'center'}>
                                        <Typography variant="body1" sx={{ zIndex: 1 }}>
                                            <span style={{ backgroundColor: '#226BF4', color: '#FFF', padding: '1vh', paddingLeft: '2vh', paddingRight: '2vh', borderRadius: 20 }}>
                                                STEP 2
                                            </span>
                                        </Typography>
                                        <img src={vietStep2_mb} alt="vietStep2_mb" width={'100vh'} />
                                        <Typography variant="body1" mt={'2vh'} sx={{ zIndex: 1 }}>
                                            Lưu mã QR
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} textAlign={'center'}>
                                    <Grid container flexDirection={'column'} alignItems={'center'}>
                                        <Typography variant="body1" sx={{ zIndex: 1 }}>
                                            <span style={{ backgroundColor: '#226BF4', color: '#FFF', padding: '1vh', paddingLeft: '2vh', paddingRight: '2vh', borderRadius: 20 }}>
                                                STEP 3
                                            </span>
                                        </Typography>
                                        <img src={vietStep3_mb} alt="vietStep3_mb" width={'100vh'} />
                                        <Typography variant="body1" mt={'2vh'} sx={{ zIndex: 1 }}>
                                            Lưu mã QR
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} textAlign={'center'}>
                                    <Grid container flexDirection={'column'} alignItems={'center'}>
                                        <Typography variant="body1" sx={{ zIndex: 1 }}>
                                            <span style={{ backgroundColor: '#226BF4', color: '#FFF', padding: '1vh', paddingLeft: '2vh', paddingRight: '2vh', borderRadius: 20 }}>
                                                STEP 4
                                            </span>
                                        </Typography>
                                        <img src={vietStep4_mb} alt="vietStep4_mb" width={'100vh'} />
                                        <Typography variant="body1" mt={'2vh'} sx={{ zIndex: 1 }}>
                                            Lưu mã QR
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} my={6}>
                            <Grid container justifyContent={'center'} alignItems={'center'}>
                                <img src={warningTriangle} alt="warningTriangle" width={'15px'} />
                                <Typography variant="body1" sx={{ textAlign: 'center' }}>
                                    <span style={{ color: '#E11D48', marginRight: 10, marginLeft: 10 }}>
                                        Không lặp lại mã quét
                                    </span>
                                </Typography>
                                <img src={warningTriangle} alt="warningTriangle" width={'15px'} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    )
}

// Default export
export default VietQR;
